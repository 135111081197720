
export function handleAnonymizeChars(str: string) {
  const size = str.length
  if(size >= 2 ) return str.substring(0, 2) + "*".repeat(size-2) 
  if (size >= 3) return str.substring(0, 3) + "*".repeat(size-3)
  return str
}

export function handleAnonymizeEmail(str: string) {
  if(!str) {
    return ""
  }

  const array = str.split("@")
  return `${handleAnonymizeChars(array[0])}@${array[1]}`
}

export function handleAnonymizeName(str: string) {
  if(!str) {
    return ""
  }

  return str.split(" ").map(elem => handleAnonymizeChars(elem)).join(" ")
}

export function handleAnonymizePhone(str: string) {
  if(!str) {
    return ""
  }

  const size = str.length
  if(size !== 10 && size !== 11) return ""

  const dd = str.substring(0, 2)
  const phoneA = str.substring(2, 4)
  const phoneB = str.substring(size - 2, size)

  if(size < 11) return `(${dd}) ${phoneA}**-**${phoneB}`
  return `(${dd}) ${phoneA}***-**${phoneB}`
}

export function handleAnonymizeCPF(str: string) {
  if(!str) {
    return ""
  }
  const cpf = str.substring(0, 11)
  const start = cpf.substring(0, 3)
  const mid = cpf.substring(6, 9)
  return `${start}.***.${mid}-**`
}
